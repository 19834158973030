import React, { useState, useEffect } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backIcon from '../../assets/images/common/back-icon.svg';
import '../../assets/css/partials/common/checkout.scss';
import {PAYMENT_URL, PAYMENT_URL_TEST} from "../../config/api";
import * as EventAction from '../../redux/actions/eventsAction';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    input: {
        width: 42,
    },
});

function EventCheckoutDetails() {
    const dispatch = useDispatch()
    const history = useHistory();

    const classes = useStyles();
    const [value, setValue] = useState(70);

    const userDetails = JSON.parse(localStorage.getItem("userData"));
    let mobile = localStorage.getItem("mobile");
    
    if(isNaN(mobile)) {
        let userDataStored = JSON.parse(localStorage.getItem("userData"));
        mobile = userDataStored.user_details.mobile;
    }
    const fullName = localStorage.getItem("full_name");
    const changedName = useSelector(state => state?.events?.nameChangedOnUpdates);
    const changedNameBool = useSelector(state => state?.events?.nameChangedOnUpdatesBool);
    const editNum = useSelector(state => state?.events?.numberChangedOnUpdates)
    const editNumBool = useSelector(state => state?.events?.numberChangedOnUpdatesBool)
    const isCorporateAccount = userDetails?.corporate_account;

    const checkoutDetails = useSelector(state => state?.events?.checkout_details);
    const bookingInformationData = useSelector(state => state?.events?.booking_info);
    const PACKAGE_DETAILS = useSelector(state => state?.events?.event_details?.data);
    const packageData = PACKAGE_DETAILS && PACKAGE_DETAILS?.event?.packages[0];

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch(EventAction.checkOut())
        if(packageData?.price === undefined || packageData?.price === null){
            history.push(`/events`);
        }
    }, []);

      /* clearing packageDetails, bookData and eventDetails key's from 
    localStorage while redirecting to bank payments page to avoid any potential flaws */
    const clearPackageAndBookingData = () => {
        localStorage.removeItem('packageDetails');
        localStorage.removeItem('bookData');
        localStorage.removeItem('eventDetails');
        dispatch(EventAction.nameChangeOnUpdate(null, false))
        dispatch(EventAction.eventEditNumberChange(null, false))
    }

    return (
        <div>
            <Helmet>
            <title>{`${packageData?.pack_title}`} - Hammock Holidays</title>
            <meta name="description" content={`${packageData?.brief_description}`} />
            </Helmet>
            <div className="innerPage-header hide-header-mobi">
                <Header />
            </div>
            <div className="container innerPage-container innerPage-container-mobi">
                <a href onClick={() => history.goBack()}>
                    <div className="back-button back-button-mobi">
                        <img src={backIcon} alt="" />
                    </div>
                </a>
                <div className="purchase-details">
                    <h1>Checkout</h1>
                    <div className="line"></div>
                    <div className="row mb-5 pb-5">
                        <div className="col-12 col-lg-7">
                           <div className="d-flex d-md-block mobi-overflow">
                                <div className="product-holder product-holder-mobi">
                                    <div className="prod-img">
                                        {/* <img src={packageData?.images[0]?.image} alt="" /> */}
                                        {packageData?.images.map(image => {
                                        if(image?.thumbnail == true){
                                            return <img src={image?.image} alt="" />
                                        }
                                    })}
                                    </div>
                                    <div className="prod-info">
                                        <h5>{packageData?.pack_title}</h5>
                                        <div className="line"></div>
                                        <div className="details">
                                            {
                                                packageData?.workshop ? <p>Online workshop</p> : null
                                            }
                                            {/* <p>Worth Rs {packageData?.price}</p> */}
                                            <p>{`${moment(bookingInformationData?.schedule?.date, 'DD-MM-YYYY').format('ddd Do MMM,')} ${moment(bookingInformationData?.schedule?.from, 'hh:mm').format('LT')} to ${moment(bookingInformationData?.schedule?.to, 'hh:mm').format('LT')}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="buyer-details buyer-details-mobi packageInfo">
                                <div className="input-holder d-block mb-0">
                                    <div className="details">
                                        <p>{moment(bookingInformationData?.schedule?.date, 'DD.MM.YYYY').format('ddd Do MMM, YYYY')}</p>
                                        <p>Selected Date</p>
                                    </div>
                               
                                </div>
                                </div>
                           </div>

                            <div className="buyer-details packageInfo">

                                <div className="input-holder d-block">
                                    <div className="details">
                                        <p>{changedNameBool ? changedName:bookingInformationData?.contact_name}</p>
                                        <p>Your Name</p>
                                    </div>
                                </div>
                                <div className="input-holder d-block">
                                    <div className="details">
                                        <p>{editNumBool?editNum:bookingInformationData?.contact_number}</p>
                                        <p>Your Contact number</p>
                                    </div>
                                </div>
                                <div className="input-holder d-block">
                                    <div className="details">
                                        <p>{bookingInformationData?.contact_email}</p>
                                        <p>Your Email id</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="price-holder">
                            <h4 className="checkout-title">{
                                checkoutDetails?.pay_mode == "DC" ? 'You are using Debit Card / UPI' : 'You are using Credit Card / Net Banking'
                            } </h4>
                                <div className="price-line w-100"></div>
                                 <form action={PAYMENT_URL} method='post'>
                                    <input type="hidden" name="firstname" value={checkoutDetails?.firstname} />
                                    <input type="hidden" name="lastname" value="" />
                                    <input type="hidden" name="surl" value={checkoutDetails?.surl} />
                                    <input type="hidden" name="phone" value={checkoutDetails?.phone} />
                                    <input type="hidden" name="key" value={checkoutDetails?.key} />
                                    <input type="hidden" name="hash" value={checkoutDetails?.hash} />
                                    <input type="hidden" name="curl" value={checkoutDetails?.curl} />
                                    <input type="hidden" name="furl" value={checkoutDetails?.furl} />
                                    <input type="hidden" name="txnid" value={checkoutDetails?.txnid} />
                                    <input type="hidden" name="productinfo" value={checkoutDetails?.productinfo} />
                                    <input type="hidden" name="amount" value={checkoutDetails?.amount} />
                                    <input type="hidden" name="email" value={checkoutDetails?.email} />
                                    <button type="submit" onClick={clearPackageAndBookingData} className="btn proceed-to-book w-100 btn-fixed-mobi" href >Pay Rs {checkoutDetails?.amount}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EventCheckoutDetails
