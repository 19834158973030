import { API, BASE_URL, CC_PAYMENT_KEY,DC_PAYMENT_KEY, TEST_PAYMENT_KEY } from "../../config/api";
import {
  axiosGetWithToken,
  axiosGet,
  axiosPost, axiosPostWithToken
} from "../../services/apiServices";
import { push } from "connected-react-router";

export const EVENTS_CATEGORY = "EVENTS_CATEGORY";
export const INITIAL_EVENTS_CATEGORY_ID = "INITIAL_EVENTS_CATEGORY_ID";
export const CATEGORY_ID = "CATEGORY_ID";
export const EVENTS_CATEGORY_ID = "EVENTS_CATEGORY_ID";
export const EVENTS_LIST = "EVENTS_LIST";
export const ERROR_ALERT = "ERROR_ALERT";
export const EVENT_PACKAGE_DETAILS = "EVENT_PACKAGE_DETAILS";
export const SELECTED_DATE = "SELECTED_DATE";
export const SELECTED_TIME = "SELECTED_TIME";
export const END_TIME = "END_TIME";
export const EVENT_BOOKING_INFORMATION_DATA = "EVENT_BOOKING_INFORMATION_DATA";
export const BOOKING_STATUS = "BOOKING_STATUS";
export const CHECKOUT_DETAILS = "CHECKOUT_DETAILS";
export const SIMILAR_EVENTS = "SIMILAR_EVENTS"
export const NAME_CHANGE_ON_UPDATE_EVENT = "NAME_CHANGE_ON_UPDATE_EVENT"
export const NAME_CHANGE_ON_UPDATE_EVENT_BOOL = "NAME_CHANGE_ON_UPDATE_EVENT_BOOL"
export const NUMBER_CHANGE_ON_UPDATE_EVENT = "NUMBER_CHANGE_ON_UPDATE_EVENT"
export const NUMBER_CHANGE_ON_UPDATE_EVENT_BOOL = "NUMBER_CHANGE_ON_UPDATE_EVENT_BOOL"
export const RESET_EVENTS_LIST = "RESET_EVENTS_LIST"
export const EVENTS_PACKAGES_LIST = "EVENTS_PACKAGES_LIST"
export const EVENTS_TESTIMONIALS = "EVENTS_TESTIMONIALS"
export const SET_PAST_EVENTS = "SET_PAST_EVENTS"

// get the events categories list
export const eventsCategories = () => {
  return async (dispatch, getState) => {
    // const token = getState().auth.token;
    axiosGet(`${API.EVENTS_CATEGORY_LIST}`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EVENTS_CATEGORY,
          payload: res.data,
        });
        // dispatch({
        //   type: CATEGORY_ID,
        //   payload: "all-events",
        // });
      }
    })
      .catch((err) => {
        dispatch({
          // type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
};

// setting category id in state
export const setCategoryId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: EVENTS_CATEGORY_ID,
      payload: id,
    });
  }
}


// POST API list of events 
export const getEvents = (id, page) => {
  return async (dispatch, getState) => {
    const catId = getState().events.cat_id;
    const body = {
      "sec_id": catId == "all-events" || catId == "past_events" ? "" : catId,
      "next_page_id": page,
      "page_size":8,
      "past_events": catId == "past_events" ? true : false
    }
    axiosPost(`${API.EVENTS_PACKAGES_LIST}`, body).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EVENTS_LIST,
          payload: res.data,
        });
        if(page == 1){
          dispatch({
            type: RESET_EVENTS_LIST,
            payload: res.data.data.listing.results,
          })
        }else{
          
          dispatch({
            type: EVENTS_PACKAGES_LIST,
            payload: res.data.data.listing.results,
          });
        }
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      })

  }
}

// GET API to get the details of individual events
export const getEventDetails = (id, loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.EVENTS_PACKAGE_DETAILS_API + id}/`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EVENT_PACKAGE_DETAILS,
          payload: res.data,
        });
        localStorage.setItem("eventDetails", JSON.stringify(res?.data))
        loader();
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        loader()
      });
  }
}
// GET API to get the testimonial of individual events
export const getTestimonials = (id) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.EVENTS_TESTIMONIALS + id}/`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EVENTS_TESTIMONIALS,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}

export const handleProceedToBook = (data, isCorporateAccount) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      "pack_id": data?.pack_id,
      "schedule": {
        "date": data?.schedule?.date,
        "from": data?.schedule?.from,
        "to": data?.schedule?.to
      },
      "total_price": data?.total_price,
      "amount": data?.points?.toFixed(2),
      "points": data?.points,
      "contact_name": data?.name,
      "contact_last_name": "",
      "contact_email": data?.email,
      "contact_number": data?.contactNumber,
    };
    localStorage.setItem("bookData", JSON.stringify(body));
    dispatch({
      type: EVENT_BOOKING_INFORMATION_DATA,
      payload: body
    })
  }
}
export const updateBookingData = (data, isCorporateAccount) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const booking_information_data = getState().events.booking_info
    const body = {
      ...booking_information_data,
      "contact_name": data?.contact_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
    };
    localStorage.setItem("bookData", JSON.stringify(body));
    dispatch({
      type: EVENT_BOOKING_INFORMATION_DATA,
      payload: body
    })
  }
}

export const handleSelectedDate = (selectedDate, time, endtime) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECTED_DATE,
      payload: selectedDate
    });
    dispatch({
      type: SELECTED_TIME,
      payload: time
    });
    dispatch({
      type: END_TIME,
      payload: endtime
    })

  }
}

// checkout function
// CONDITION 1: If points are greater then amount pay with points and amount will be equal to zero (for corporate users only).
// CONDITION 2: Else pay with amount redirect's to bank payment screen (can also use points and can pay balance amount) for corporate users.
// CONDITION 3: for non corporate users
export const checkOut = (data, corporate, paidWithPoints, payAmount, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const isCorporateAccount = corporate
    const body = {
      "pack_id": data?.pack_id,
      "schedule": {
        "date": data?.schedule?.date,
        "from": data?.schedule?.from,
        "to": data?.schedule?.to
      },
      "total_points": data?.total_points,
      "total_price": data?.total_price,
      "amount": data?.amount,
      "contact_name": data?.contact_name,
      // "contact_last_name": data?.contact_last_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
      "pay_mode" : data?.amount > 0 ? data?.pay_mode : "",
    };
    if (data?.amount === 0) {
      axiosPostWithToken(`${API.EVENT_BOOKING_CHECKOUT}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          if (res?.data?.data?.serializer?.booking_status === "confirmed") {
            dispatch({
              type: BOOKING_STATUS,
              payload: res.data,
            });
            dispatch({
              type: EVENT_BOOKING_INFORMATION_DATA,
              payload: body
            })
            paidWithPoints();
          }
        }
        else if (res.data.status_code === 400) {
          handleErrors(res?.data?.data?.message)
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        })
    }
    else if (isCorporateAccount === true) {
      axiosPostWithToken(`${API.EVENT_BOOKING_PAYMENT_GATEWAY}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          const resBody = res?.data?.data;
          const paymentBody = {
            surl: `${BASE_URL}/user/payment/event-success/`,
            curl: `${BASE_URL}/user/payment/cancelled/`,
            furl: `${BASE_URL}/user/payment/event-failure/`,
            key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
            // key: `${TEST_PAYMENT_KEY}`, // test server payment's key
            phone: data?.contact_number
          }

          const payment_details = { ...resBody, ...paymentBody };
          localStorage.setItem("bookData", JSON.stringify(body));
          dispatch({
            type: CHECKOUT_DETAILS,
            payload: payment_details,
          });
          dispatch({
            type: EVENT_BOOKING_INFORMATION_DATA,
            payload: body
          })
          payAmount();
        }
        else if (res.data.status_code === 400) {
          handleErrors(res?.data?.data?.message)
          // console.log("res", res?.data?.data?.message);
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
    else if (isCorporateAccount === false) {
      axiosPostWithToken(`${API.EVENT_USER_BOOKING_PAYMENT_GATEWAY}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          const resBody = res?.data?.data;
          const paymentBody = {
            surl: `${BASE_URL}/user/payment/event-user-success/`,
            curl: `${BASE_URL}/user/payment/cancelled/`,
            furl: `${BASE_URL}/user/payment/event-user-failure/`,
            key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
            // key: `${TEST_PAYMENT_KEY}`, // test server payment's key
            phone: data?.contact_number
          }
          const payment_details = { ...resBody, ...paymentBody }
          localStorage.setItem("bookData", JSON.stringify(body));
          dispatch({
            type: CHECKOUT_DETAILS,
            payload: payment_details,
          });
          dispatch({
            type: EVENT_BOOKING_INFORMATION_DATA,
            payload: body
          })
          payAmount()
        }
        else if (res.data.status_code === 400) {
          handleErrors(res?.data?.data?.message)
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
    return
  }
};

//Free event booking action
export const freeEventBooking = (data, corporate, paidWithPoints, payAmount, handleErrors, handleSuccess) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const isCorporateAccount = corporate
    const body = {
      "pack_id": data?.pack_id,
      "schedule": {
        "date": data?.schedule?.date,
        "from": data?.schedule?.from,
        "to": data?.schedule?.to
      },
      "amount": data?.amount,
      "contact_name": data?.contact_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
      "corporate": corporate
    };
    if (data?.amount === 0) {
      axiosPostWithToken(`${API.EVENT_FREE_BOOKING}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          if (res?.data?.data?.serializer?.booking_status === "confirmed") {
            dispatch({
              type: BOOKING_STATUS,
              payload: res.data,
            });
            dispatch({
              type: EVENT_BOOKING_INFORMATION_DATA,
              payload: body
            })
            handleSuccess()
            
          }
        }
        else if (res.data.status_code === 400) {
          handleErrors(res?.data?.data?.message)
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        })
    }
  }
}

// GET API to get similar events 
export const getSimilarEvents = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id
    };
    axiosGetWithToken(`${API.SIMILAR_EVENTS_API + id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SIMILAR_EVENTS,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });

  }
}

export const nameChangeOnUpdate = (name, bool) => {
  console.log("name", name);
  return async (dispatch, getState) => {
    dispatch({
      type: NAME_CHANGE_ON_UPDATE_EVENT,
      payload: name
    });
    dispatch({
      type: NAME_CHANGE_ON_UPDATE_EVENT_BOOL,
      payload: bool
    });

  }
}
export const eventEditNumberChange = (number, bool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: NUMBER_CHANGE_ON_UPDATE_EVENT,
      payload: number
    });
    dispatch({
      type: NUMBER_CHANGE_ON_UPDATE_EVENT_BOOL,
      payload: bool
    });

  }
}
export const setPastEvents = (bool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_PAST_EVENTS,
      payload: bool
    });

  }
}