
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/database";

const config = {
    // staging server
    apiKey: "AIzaSyDL4LKu_cxBblwipNolWZCbqd2E9qF8zqg",
    authDomain: "hammock-holidays-staging.firebaseapp.com",
    projectId: "hammock-holidays-staging",
    storageBucket: "hammock-holidays-staging.appspot.com",
    messagingSenderId: "667915764308",
    appId: "1:667915764308:web:d5a9421fd1476d35e7a3c1"
};

// export const createUserProfileDocument = async (userAuth, additionalData) => {
//     if(!userAuth) return;

//     const userRef = firestore.doc(`users/${userAuth.uid}`)
//     const snapShot = await userRef.get();
//    console.log('firebase', snapShot)
//     if(!snapShot.exists){
//         const { displayName, email } = userAuth;
//         const createAt = new Date();

//         try {
//             await userRef.set({
//                 displayName,
//                 email,
//                  createAt,
//                  ...additionalData
//             })
//         } catch (error) {
//             console.log('error creatinguser', error.message)
//         }
//     };
//     return userRef;
// }

// Initialize Firebase
// firebase.initializeApp(config);

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}

export const db = firebase.database();
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => {
    console.log("provider===>1", provider)
    auth.signInWithPopup(provider).then(response => {

        console.log("response---->", response.additionalUserInfo.isNewUser)
        if (response.additionalUserInfo.isNewUser === true) {
            console.log("logdin sucessfully!")
        } else {
            console.log("User allreday exist!s")
        }
        // localStorage.setItem("accessToken")
    });
}

export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebook = () => {
    auth.signInWithRedirect(facebookProvider).then(response => {
        console.log("res", response)
        if (response.additionalUserInfo.isNewUser === true) {
            console.log("logdin sucessfully!")
        } else {
            console.log("User allreday exist!s")
        }
    })
        .catch(err => console.log(err))
}
export default firebase;